import React, { useState } from 'react';
import { Form } from 'reactstrap';
import EtiquetaArchivo from './EtiquetaArchivo';
import { postImagen } from 'services/FileUploadServices';


function SubirFotoComponent(props) {
    const {setFoto} = props
    const [EstadoArchivo, setEstadoArchivo] = useState(null);

    const SubirImagen=async(event) => {
        setEstadoArchivo(1);
        try {
          let image = event.target.files[0];
          let formD = new FormData(); 
         
          // Update the formData object 
          formD.append( 
            "image", 
            image,
            image.name
          ); 
          
          let result = await postImagen(formD);
          if(result.ok){
            setFoto(result.url);
            setEstadoArchivo(2)
            
          }
          else{
            setEstadoArchivo(3)
          }
        } catch (error) {
          setEstadoArchivo(3)
        }
    }

    return (
        <Form> Foto:
            <div className="custom-file">
            <input
                className="custom-file-input"
                id="customFileLang"
                lang="en"
                type="file"
                onChange={SubirImagen}
            />
            <label className="custom-file-label" htmlFor="customFileLang">
                Archivo
            </label>
            </div>
            <EtiquetaArchivo Estado={EstadoArchivo}/>
        </Form>
    );
}

export default SubirFotoComponent;