import { urlGlobal2 } from './GlobalService.js';
import axios, {post} from 'axios' 

export async function postImagen(formD){
    let url = `${urlGlobal2}/files`;
    try {
        let token = localStorage.getItem('token');
        let headers = {'Content-Type':'multipart/form-data', token}
        let {data} = await post(url,formD,{headers})
        return data;
    } catch (error) {
        
        return null;
    }
  }