import React from "react";
import Casos from "./subvistas/CasosClientes";
import Cuotas from "./subvistas/Cuotas";
import Equipos from "./subvistas/Equipos";
import Facturas from "./subvistas/FacturasCliente";
import General from "./subvistas/General";
import Observaciones from "./subvistas/Observaciones";
import Pagos from "./subvistas/Pagos";
import Reclamos from "./subvistas/Reclamos";


function ContenedorSubvistas(props){
    const {vista, datos, planCliente, idClient} = props;

    if(vista === "General"){
        return (
            <>
                <General datos={datos} planCliente={planCliente} idClient={idClient}/>
            </>
        )
    }else if(vista === "Reclamos"){
        return (
            <>
                <Reclamos/>
            </>
        )
    }else if(vista === "Equipos"){
        return (
            <>
                <Equipos idClient={idClient}/>
            </>
        )
    }else if(vista === "Pagos"){
        return (
            <>
                <Pagos idClient={idClient} datos={datos} />
            </>
        )
    }else if(vista === "Facturas"){
        return (
            <>
                <Facturas idClient={idClient} datosCliente={datos}/>
            </>
        )
    }else if(vista === "Observaciones"){
        return (
            <>
                <Observaciones idClient={idClient}/>
            </>
        )
    }else if(vista === "Casos"){
        return (
            <>
                <Casos idClient={idClient}/>
            </>
        )
    }else if(vista === "Cuotas"){
        return (
            <>
                <Cuotas/>
            </>
        )
    }else{
        <></>
    }  
}



export default ContenedorSubvistas;