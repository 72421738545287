import SubirFotoComponent from 'pages/components/SubirFoto';
import { warningAlert } from 'pages/components/SweetAlert';
import { successAlert } from 'pages/components/SweetAlert';
import React, { useState } from 'react';
import { Button, Card, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from 'reactstrap';
import { postImagen } from 'services/FileUploadServices';
import { postVehiculo } from 'services/VehiculosServices';

const ModalAggVehiculo = props => {

    const {ModalAgregarVehiculo, setModalAgregarVehiculo, traerVehiculos}=props;
    const [alertSweet, setAlert] = useState(null);
    const [marca, setMarca] = useState("");
    const [modelo, setModelo] = useState("");
    const [patente, setPatente] = useState("");
    const [año, setAño] = useState("");
    const [chasis, setChasis] = useState("");
    const [motor, setMotor] = useState("");
    const [foto, setFoto] = useState(null)  
    const [EstadoArchivo, setEstadoArchivo] = useState(null);


    const cambiarMarca = event => {
        setMarca(event.target.value);
    };
    const cambiarModelo = event => {
    setModelo(event.target.value);
    };
    const cambiarPatente = event => {
    setPatente(event.target.value);
    };
    const cambiarAño = event => {
    setAño(event.target.value);
    };
    const cambiarChasis = event => {
    setChasis(event.target.value);
    };
    const cambiarMotor = event => {
    setMotor(event.target.value);
    };


    const AgregarVehiculo = async()=>{
    
        let vehic = {
          marca: marca,
          modelo: modelo,
          patente: patente,
          año: año,
          nroChasis: chasis,
          nroMotor: chasis,
          estadoId: 1,
          foto: foto,
        }
      let result = await postVehiculo(vehic)
      if(result != null){
        setMarca("")
        setModelo("")
        setPatente("")
        setAño("")
        setChasis("")
        setMotor("")
        setModalAgregarVehiculo(false)
        successAlert(setAlert, "Vehiculo agregado correctamente!", "")
        traerVehiculos()
    }else{
      warningAlert(setAlert, 'No se pudo agregar el vehiculo')
    }
    }

  return (
    <>
          {alertSweet}
      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={ModalAgregarVehiculo}
                            toggle={() => setModalAgregarVehiculo(false)}
                          >
                          <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                              <CardHeader className="bg-transparent pb-5">
                                <div className="text-center"><b>Agregar Vehículo</b>
                                <button
                                  aria-label="Close"
                                  className="close"
                                  data-dismiss="modal"
                                  type="button"
                                  onClick={() => setModalAgregarVehiculo(false)}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                                </div>
                                <div className="text-muted mt-2 mb-3">
                                <Form role="form">
                                <FormGroup className="mb-3">Marca:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input 
                                      placeholder="Marca" 
                                      type="text"
                                      value={marca} 
                                      onChange={cambiarMarca}
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                  <FormGroup className="mb-3" >Modelo:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        </InputGroupText>                                        
                                      </InputGroupAddon>
                                      <Input 
                                      placeholder="Modelo" 
                                      type="text"
                                      value={modelo} 
                                      onChange={cambiarModelo}
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                  <FormGroup > Año:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input 
                                      placeholder="Año" 
                                      type="number"
                                      value={año} 
                                      onChange={cambiarAño} 
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                <FormGroup className="mb-3" >Patente:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input 
                                      placeholder="Patente..." 
                                      type="text"
                                      value={patente} 
                                      onChange={cambiarPatente} 
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                  <FormGroup > Número de Chasis:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input 
                                      placeholder="Número de Chasis" 
                                      type="text"
                                      value={chasis} 
                                      onChange={cambiarChasis} 
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                  <FormGroup className="mb-3" >Número de Motor:
                                    <InputGroup className="input-group-alternative mt-2">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input 
                                      placeholder="Número de Motor" 
                                      type="text" 
                                      value={motor} 
                                      onChange={cambiarMotor}
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                  </Form>                     
                                  <SubirFotoComponent setFoto={setFoto}/>
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="primary"
                                      type="button"
                                      onClick={()=>AgregarVehiculo()}
                                    >
                                      Agregar
                                    </Button>
                                
                                    <Button
                                      color="secondary"
                                      data-dismiss="modal"
                                      type="button"
                                      onClick={() => {setEstadoArchivo(null);setModalAgregarVehiculo(false)}}
                                    >
                                      Cerrar
                                    </Button>                    
                                  </div>
                                </div>                    
                              </CardHeader>                  
                            </Card>
                          </div>
                          </Modal>
    </>
  )
};

export default ModalAggVehiculo