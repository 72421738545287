import EtiquetaArchivo from 'pages/components/EtiquetaArchivo';
import React, { useState } from 'react';
import { Form } from 'reactstrap';
import { postImagen } from 'services/FileUploadServices';


function SubirComprobante(props) {
    const {setComprobanteId} = props
    const [EstadoArchivo, setEstadoArchivo] = useState(null);

    const SubirImagen=async(event) => {
        setEstadoArchivo(1);
        try {
          let image = event.target.files[0];
          let formD = new FormData(); 
         
          // Update the formData object 
          formD.append( 
            "archivo", 
            image,
            image.name
          ); 
          
          let result = await postImagen(formD);
          if(result.ok){
            if (result.img !== null) {
              setEstadoArchivo(2)
              setComprobanteId(result.img);
              
            } else {
              setEstadoArchivo(2)
              setComprobanteId(result.url);
            }
            
          }
          else{
            setEstadoArchivo(3)
          }
        } catch (error) {
          setEstadoArchivo(3)
        }
    }

    return (
      <>
            <div className="custom-file">
            <input
                className="custom-file-input"
                id="customFileLang"
                lang="en"
                type="file"
                onChange={SubirImagen}
                />
            <label className="custom-file-label" htmlFor="customFileLang">
                Archivo
            </label>
            </div>
            <EtiquetaArchivo Estado={EstadoArchivo}/>
      </>
    );
}

export default SubirComprobante;